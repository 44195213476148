import classNames from 'classnames'
import React from 'react'

type ButtonProps = {
  children: React.ReactNode
  href?: string
  onClick?: () => void
  className?: string
  target?: string
  size?: 'small' | 'medium'
  type?: 'button' | 'submit' | 'reset'
  theme?: 'primary' | 'secondary' | 'tertiary'
  disabled?: boolean
}

const Button = React.forwardRef(
  (
    {
      children,
      className,
      type,
      theme = 'primary',
      size = 'medium',
      href,
      ...props
    }: ButtonProps,
    ref: any,
  ) => {
    const Component = href ? 'a' : 'button'

    // Define theme-based classes
    const themeClasses = {
      primary:
        'text-white bg-purple-heart px-5 hover:bg-tuna hover:text-white hover:border-tuna',
      secondary:
        'bg-white border-tuna px-5 hover:bg-tuna hover:text-white hover:border-tuna',
      tertiary: '',
    }

    // Define size-based classes
    const sizeClasses = {
      small: 'py-2',
      medium: theme !== 'tertiary' ? 'py-3' : '',
    }

    return (
      <Component
        ref={ref}
        type={Component === 'button' ? type : undefined}
        className={classNames(
          'inline-flex items-center font-medium',
          theme !== 'tertiary'
            ? ' justify-center border border-transparent rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-heart disabled:opacity-30 disabled:cursor-not-allowed'
            : '',
          sizeClasses[size],
          themeClasses[theme],
          className,
        )}
        href={href}
        {...props}
      >
        {children}
      </Component>
    )
  },
)

Button.displayName = 'Button'

export default Button
